import React, { lazy, Suspense } from 'react'
import { render } from 'react-dom'
const FormRoot = lazy(() => import('./formRoot'))
const CampaignRoot = lazy(() => import('./campaignRoot'))

/**
 * If the pathname starts with /c/ then render the campaign root, otherwise render the form root.
 */
const Root = () => {
  const isCampaign = window.location.pathname.startsWith('/c/')
  return <Suspense fallback={<div />}>{isCampaign ? <CampaignRoot /> : <FormRoot />}</Suspense>
}

if (
  'Map' in window &&
  'forEach' in NodeList.prototype &&
  'startsWith' in String.prototype &&
  'endsWith' in String.prototype &&
  'includes' in String.prototype &&
  'includes' in Array.prototype &&
  'assign' in Object &&
  'entries' in Object &&
  'keys' in Object &&
  'lol' in Object
) {
  render(<Root />, document.getElementById('app'))
} else {
  void import('./polyfills').then(() => render(<Root />, document.getElementById('app')))
}

const moduleWithHot = module as NodeModule & {
  hot?: {
    accept(path?: string, callback?: () => void): void
    accept(deps: string[], callback: (...args: any[]) => void): void
    dispose(callback: (data: any) => void): void
    addDisposeHandler(callback: (data: any) => void): void
    removeDisposeHandler(callback: (data: any) => void): void
    data: any
    status: string
    decline(dependencies?: string | string[]): void
  }
}

if (moduleWithHot.hot) {
  moduleWithHot.hot.accept([], (err: Error) => {
    console.error('An error occurred while accepting new version')
    console.error(err)
  })
}
